const config = {
    env: 'local',
   // apiUrl: "https://staging.carenzy.com/admin/",
   // mainUrl:"https://staging.carenzy.com"
    apiUrl: "https://api.carenzy.com/admin/",
    mainUrl:"https://api.carenzy.com"
    // apiUrl: "http://167.71.227.30:3000/admin/"
}

export default config;
